<template>
	<!-- ======= Header ======= -->
	<header id="header" class="fixed-top ">
		<div class="container d-flex align-items-center justify-content-lg-between">

			<h1 class="logo me-auto me-lg-0"><a href="index.html">HZG<span>.</span></a></h1>
			<!-- Uncomment below if you prefer to use an image logo -->
			<!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

			<nav id="navbar" class="navbar order-last order-lg-0">
				<ul>
					<li><a class="nav-link scrollto active" href="#hero">首页</a></li>
					<li><a class="nav-link scrollto" href="#about">公司主营</a></li>
					<li><a class="nav-link scrollto" href="#services">合作伙伴</a></li>
					<li><a class="nav-link scrollto " href="#portfolio">公司案例</a></li>
					<!-- 					<li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
						<ul>
							<li><a href="#">Drop Down 1</a></li>
							<li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i
										class="bi bi-chevron-right"></i></a>
								<ul>
									<li><a href="#">Deep Drop Down 1</a></li>
									<li><a href="#">Deep Drop Down 2</a></li>
									<li><a href="#">Deep Drop Down 3</a></li>
									<li><a href="#">Deep Drop Down 4</a></li>
									<li><a href="#">Deep Drop Down 5</a></li>
								</ul>
							</li>
							<li><a href="#">Drop Down 2</a></li>
							<li><a href="#">Drop Down 3</a></li>
							<li><a href="#">Drop Down 4</a></li>
						</ul>
					</li> -->
					<li><a class="nav-link scrollto" href="#contact">联系我们</a></li>
				</ul>
				<i class="bi bi-list mobile-nav-toggle"></i>
			</nav><!-- .navbar -->

			<a href="#about" class="get-started-btn scrollto">Go</a>

		</div>
	</header><!-- End Header -->

	<!-- ======= Hero Section ======= -->
	<section id="hero" class="d-flex align-items-center justify-content-center">
		<div class="container" data-aos="fade-up">

			<div class="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
				<div class="col-xl-6 col-lg-8">
					<h1>大连慧智高科技软件产业有限公司<span>.</span></h1>
					<h2>欢迎您的到来!</h2>
				</div>
			</div>

			<div class="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
				<div class="col-xl-2 col-md-4">
					<div class="icon-box">
						<i class="ri-store-line"></i>
						<h3><a href="#hero">首页</a></h3>
					</div>
				</div>
				<div class="col-xl-2 col-md-4">
					<div class="icon-box">
						<i class="ri-bar-chart-box-line"></i>
						<h3><a href="#about">公司主营</a></h3>
					</div>
				</div>
				<div class="col-xl-2 col-md-4">
					<div class="icon-box">
						<i class="ri-calendar-todo-line"></i>
						<h3><a href="#services">合作伙伴</a></h3>
					</div>
				</div>
				<div class="col-xl-2 col-md-4">
					<div class="icon-box">
						<i class="ri-paint-brush-line"></i>
						<h3><a href="#portfolio">公司案例</a></h3>
					</div>
				</div>
				<div class="col-xl-2 col-md-4">
					<div class="icon-box">
						<i class="ri-database-2-line"></i>
						<h3><a href="#contact">联系我们</a></h3>
					</div>
				</div>
			</div>

		</div>
	</section><!-- End Hero -->

	<main id="main">
		<!-- ======= About Section ======= -->
		<section id="about" class="about">
			<div class="container" data-aos="fade-up">

				<div class="row">
					<div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
						<img src="assets/img/about.jpg" class="img-fluid" alt="">
					</div>
					<div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right"
						data-aos-delay="100">
						<h3>大连慧智高科技软件产业有限公司.</h3>
						<p class="fst-italic">
							大连慧智高科技软件产业有限公司致力于,网站制作,网站建设,网站优化,网站设计,软件开发,微信公众号/小程序开发,跨平台uin-app开发,网站推广,政府网站建设,自助建站,AI智能,域名注册,云服务器等多项互联网应用服务.
						</p>
						<ul>
							<li><i class="ri-check-double-line"></i> 开启提供全方位的互联网+产品及服务，与客户共同成长，共创价值.</li>
							<li><i class="ri-check-double-line"></i> 多年的网站建设经验，辰逸科技积累了各行业的案例，提供更专业的技术及设计服务.</li>
							<li><i class="ri-check-double-line"></i> 让客户觉得满意,才是辰逸的职责.</li>
						</ul>
						<p>
							24小时待命,为您服务
						</p>
					</div>
				</div>

			</div>
		</section><!-- End About Section -->

		<!-- ======= Clients Section ======= -->
		<section id="clients" class="clients">
			<div class="container" data-aos="zoom-in">

				<div class="clients-slider swiper">
					<div class="swiper-wrapper align-items-center">
						<div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt="">
						</div>
						<div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt="">
						</div>
					</div>
					<div class="swiper-pagination"></div>
				</div>

			</div>
		</section><!-- End Clients Section -->

		<!-- ======= Features Section ======= -->
		<section id="features" class="features">
			<div class="container" data-aos="fade-up">

				<div class="row">
					<div class="image col-lg-6" style='background-image: url("assets/img/features.jpg");'
						data-aos="fade-right"></div>
					<div class="col-lg-6" data-aos="fade-left" data-aos-delay="100">
						<div class="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
							<i class="bx bx-receipt"></i>
							<h4>网站建设</h4>
							<p>Website construction</p>
						</div>
						<div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
							<i class="bx bx-cube-alt"></i>
							<h4>微信公众号/小程序开发</h4>
							<p>WeChat official account/applet development</p>
						</div>
						<div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
							<i class="bx bx-images"></i>
							<h4>App开发</h4>
							<p>App development</p>
						</div>
						<div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
							<i class="bx bx-shield"></i>
							<h4>网站优化、维护</h4>
							<p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
						</div>
					</div>
				</div>

			</div>
		</section><!-- End Features Section -->

		<!-- ======= Services Section ======= -->
		<section id="services" class="services">
			<div class="container" data-aos="fade-up">

				<div class="section-title">
					<h2>You friend</h2>
					<p>合作伙伴</p>
				</div>

				<div class="row">
					<div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
						<div class="icon-box">
							<img src="./assets/img/阿里.png" alt="">
							<h4><a href="">阿里云</a></h4>
							<p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
						data-aos-delay="200">
						<div class="icon-box">
							<img src="./assets/img/腾讯云.png" alt="">
							<h4><a href="">腾讯云</a></h4>
							<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
						data-aos-delay="300">
						<div class="icon-box">
							<img src="./assets/img/华为云.png" alt="">
							<h4><a href="">华为云</a></h4>
							<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
						data-aos-delay="100">
						<div class="icon-box">
							<img src="./assets/img/链家.png" alt="">
							<h4><a href="">链家</a></h4>
							<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
						data-aos-delay="200">
						<div class="icon-box">
							<img src="./assets/img/链家.png" alt="">
							<h4><a href="">辽宁固得</a></h4>
							<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
						data-aos-delay="300">
						<div class="icon-box">
							<img src="./assets/img/汇智.png" alt="">
							<h4><a href="">汇智采编</a></h4>
							<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
						</div>
					</div>

				</div>

			</div>
		</section><!-- End Services Section -->

		<!-- ======= Cta Section ======= -->
		<section id="cta" class="cta">
			<div class="container" data-aos="zoom-in">

				<div class="text-center">
					<h3>公司职责</h3>
					<p>大连慧智高科技软件产业有限公司在飞速发展的同时，将立足于发挥自身优势、聚集网络精英，力争做建站软件市场的主导者、网络技术的引领者、客户服务的佼佼者。
						展望未来，辰逸充满信心，我们将以更为优质的服务、更为杰出的成果回报广大客户对我们的厚爱及支持.</p>
					<a class="cta-btn" href="#">Go</a>
				</div>

			</div>
		</section><!-- End Cta Section -->

		<!-- ======= Portfolio Section ======= -->
		<section id="portfolio" class="portfolio">
			<div class="container" data-aos="fade-up">

				<div class="section-title">
					<h2>You friend</h2>
					<p>公司案例</p>
				</div>

				<div class="row" data-aos="fade-up" data-aos-delay="100">

				</div>

				<div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

					<div class="col-lg-4 col-md-6 portfolio-item filter-app">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>商城、婚庆</h4>
								<p>App、小程序</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-web">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>医院</h4>
								<p>Web</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-app">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>房地产</h4>
								<p>Web、App</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="App 2"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-card">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>办公系统</h4>
								<p>Web</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="Card 2"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-web">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>点餐</h4>
								<p>Web、小程序、App</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="Web 2"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-app">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>教育、课程</h4>
								<p>Web、小程序、App</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="App 3"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-card">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>社交游戏</h4>
								<p>App</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="Card 1"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 portfolio-item filter-card">
						<div class="portfolio-wrap">
							<img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
							<div class="portfolio-info">
								<h4>金融借贷</h4>
								<p>Web</p>
								<div class="portfolio-links">
									<a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery"
										class="portfolio-lightbox" title="Card 3"><i class="bx bx-plus"></i></a>
									<a href="#" title="More Details"><i class="bx bx-link"></i></a>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</section><!-- End Portfolio Section -->

		<!-- ======= Counts Section ======= -->
		<section id="counts" class="counts">
			<div class="container" data-aos="fade-up">

				<div class="row no-gutters">
					<div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
						data-aos="fade-right" data-aos-delay="100"></div>
					<div class="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left"
						data-aos-delay="100">
						<div class="content d-flex flex-column justify-content-center">
							<h3>诚信为本，服务客户</h3>
							<p>
								免费设计、体验,我们是专业的,不满意则无需要付费!
							</p>
							<div class="row">
								<div class="col-md-6 d-md-flex align-items-md-stretch">
									<div class="count-box">
										<i class="bi bi-emoji-smile"></i>
										<span data-purecounter-start="0" data-purecounter-end="65"
											data-purecounter-duration="2" class="purecounter"></span>
										<p><strong>Happy Clients</strong> consequuntur voluptas nostrum aliquid ipsam
											architecto ut.</p>
									</div>
								</div>

								<div class="col-md-6 d-md-flex align-items-md-stretch">
									<div class="count-box">
										<i class="bi bi-journal-richtext"></i>
										<span data-purecounter-start="0" data-purecounter-end="85"
											data-purecounter-duration="2" class="purecounter"></span>
										<p><strong>Projects</strong> adipisci atque cum quia aspernatur totam laudantium
											et quia dere tan</p>
									</div>
								</div>

								<div class="col-md-6 d-md-flex align-items-md-stretch">
									<div class="count-box">
										<i class="bi bi-clock"></i>
										<span data-purecounter-start="0" data-purecounter-end="35"
											data-purecounter-duration="4" class="purecounter"></span>
										<p><strong>Years of experience</strong> aut commodi quaerat modi aliquam nam
											ducimus aut voluptate non vel</p>
									</div>
								</div>

								<div class="col-md-6 d-md-flex align-items-md-stretch">
									<div class="count-box">
										<i class="bi bi-award"></i>
										<span data-purecounter-start="0" data-purecounter-end="20"
											data-purecounter-duration="4" class="purecounter"></span>
										<p><strong>Awards</strong> rerum asperiores dolor alias quo reprehenderit eum et
											nemo pad der</p>
									</div>
								</div>
							</div>
						</div><!-- End .content-->
					</div>
				</div>

			</div>
		</section><!-- End Counts Section -->

		<!-- ======= Testimonials Section ======= -->
		<section id="testimonials" class="testimonials">
			<div class="container" data-aos="zoom-in">

				<div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
					<div class="swiper-wrapper" style="align-items: center;">

						<div class="swiper-slide">
							<div class="testimonial-item">
								<h3>HZG<span>.</span></h3>
								<p>
									<i class="bx bxs-quote-alt-left quote-icon-left"></i>
									专业的技术团队,优质的科技服务,给您带来更安全的互联网体验.
									<i class="bx bxs-quote-alt-right quote-icon-right"></i>
								</p>
							</div>
						</div><!-- End testimonial item -->

					</div>
					<div class="swiper-pagination"></div>
				</div>

			</div>
		</section><!-- End Testimonials Section -->
		<!-- ======= Contact Section ======= -->
		<section id="contact" class="contact">
			<div class="container" data-aos="fade-up">

				<div class="section-title">
					<h2>You friend</h2>
					<p>联系我们</p>
				</div>
				<div class="row mt-5">

					<div class="col-lg-4">
						<div class="info">
							<div class="address">
								<i class="bi bi-geo-alt"></i>
								<h4>Location:</h4>
                <p>大连市甘井子区红岗路6号（大连理工创业园一期203</p>
							</div>

							<div class="email">
								<i class="bi bi-envelope"></i>
								<h4>Email:</h4>
								<p>659822559@qq.com</p>
							</div>

							<div class="phone">
								<i class="bi bi-phone"></i>
								<h4>Call:</h4>
								<p>+159 9170 7023 </p>
							</div>

						</div>

					</div>


					<div class="col-lg-8 mt-5 mt-lg-0">

						<form action="" method="post" role="form" class="php-email-form">
							<div class="row">
								<div class="col-md-6 form-group">
									<input type="text" name="name" class="form-control" id="name"
										placeholder="Your Name" required>
								</div>
								<div class="col-md-6 form-group mt-3 mt-md-0">
									<input type="email" class="form-control" name="email" id="email"
										placeholder="Your Email" required>
								</div>
							</div>
							<div class="form-group mt-3">
								<input type="text" class="form-control" name="subject" id="subject"
									placeholder="Subject" required>
							</div>
							<div class="form-group mt-3">
								<textarea class="form-control" name="message" rows="5" placeholder="Message"
									required></textarea>
							</div>
							<div class="my-3">
								<div class="loading">Loading</div>
								<div class="error-message"></div>
								<div class="sent-message">Your message has been sent. Thank you!</div>
							</div>
							<div class="text-center"><button type="submit">Send Message</button></div>
						</form>

					</div>

				</div>

			</div>
		</section><!-- End Contact Section -->

	</main><!-- End #main -->

	<!-- ======= Footer ======= -->
	<footer id="footer">
		<div class="footer-top">
			<div class="container">
				<div class="row">

					<div class="col-lg-3 col-md-6">
						<div class="footer-info">
							<h3>HZG<span>.</span></h3>
							<p>
								详细地址:<br>
					      大连市甘井子区红岗路6号（大连理工创业园一期203）[大连市]<br><br>
								<strong>Phone:</strong> +15040541111<br>
								<strong>Email:</strong> 659822559@qq.com<br>
							</p>
							<div class="social-links mt-3">
								<a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
								<a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
								<a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
								<a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
								<a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
							</div>
						</div>
					</div>

					<div class="col-lg-2 col-md-6 footer-links">
						<h4>Useful Links</h4>
						<ul>
							<li><i class="bx bx-chevron-right"></i> <a href="#hero">首页</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="#about">公司主营</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="#services">合作伙伴</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="#portfolio">公司案例</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="#contact">联系我们</a></li>
						</ul>
					</div>
					<div class="col-lg-3 col-md-6 footer-links">
						<h4>Our Services</h4>
						<ul>
							<li><i class="bx bx-chevron-right"></i> <a href="https://www.aliyun.com/">阿里云</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="https://cloud.tencent.com/">腾讯云</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="https://activity.huaweicloud.com/">华为云</a></li>
							<li><i class="bx bx-chevron-right"></i> <a href="https://mp.weixin.qq.com/">微信公众号</a></li>
						</ul>
					</div>

				</div>
			</div>
		</div>

		<div class="container">
			<div class="copyright">
				 Copyright©2018-2024 加益高科技成果转化中心 版权所有 
			</div>
			<div class="credits">
				<div  class="credits"><a  style="color: #a2cce3" href="https://beian.miit.gov.cn/#/Integrated/index">辽ICP备2021008013号-1 </a>举报监督邮箱：659822559@qq.com </div>
			</div>
		</div>
	</footer><!-- End Footer -->
	<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
			class="bi bi-arrow-up-short"></i></a>
	<div id="preloader" ></div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {


			}
		},

	}
</script>

<style lang="less" scoped>
	#header {
		background: #000;

	}

		.testimonial-item{
			h3{
				margin-top: 30px;
				font-size: 48px;
				span{
						color: #ffc451;
				}
			}
			p{
				i:nth-of-type(1){
					margin-top: 30px;
				}
			}
	}
</style>
